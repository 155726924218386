import * as React from "react"
import { FunctionComponent } from "react"
import Header from "../../components/header/Header"
import { graphql } from "gatsby"
import {
  Wrap,
  Subtitle,
  Partners,
  Image,
  Html,
  Info,
  WorkingHours,
} from "./Lunch.css"
import MainWrap from "../../components/main-wrap/MainWrap"
import MainTitle from "../../components/main-title/MainTitle"
import { HolderWrap } from "../../components/main-wrap/MainWrap.css"
import { Lang, PageContext } from "../../constants"
import * as ReactMarkdown from "react-markdown"
export type Props = {
  data?: any
  pageContext: PageContext
}
export type Functions = {}
export type AllProps = Props & Functions

const Lunch: FunctionComponent<AllProps> = ({ data, pageContext }) => {
  const allMarkdownRemark = data.allMarkdownRemark

  const { frontmatter } = allMarkdownRemark.nodes[0]

  const getValue = (param, object = null) => {
    const query = object || data.allMarkdownRemark.nodes[0].frontmatter
    try {
      return pageContext.lang === Lang.LV ? query[param] : query[`${param}_en`]
    } catch (e) {
      console.warn(e)
      return ""
    }
  }

  return (
    <MainWrap>
      <Header lang={pageContext.lang} title={"Pusdienu Piedāvājums"} />
      <MainTitle>{pageContext.lang ===Lang.LV ? "Pusdienu Piedāvājums" : "Lunch"}</MainTitle>
      <HolderWrap>
        <Subtitle>{getValue("subTitle")}</Subtitle>

        {/*<Partners>
          {frontmatter.partners.map(partner => (
            <li key={partner.url}>
              <a href={partner.url} target="_blank">
                <Image src={partner.image} />
              </a>
            </li>
          ))}
        </Partners>*/}
        <Info>
          <li>
            <Html>
              <ReactMarkdown source={getValue("bodyMarkdown")} />
            </Html>
            <WorkingHours>
              <p>{getValue("deliveryTitle")}</p>
              {frontmatter.deliveryTimes.map(delivery => (
                <p key={getValue("days", delivery)}>
                  <span>{getValue("days", delivery)}</span> {delivery.time}
                </p>
              ))}
            </WorkingHours>
          </li>
          <li style={{ backgroundImage: `url(${frontmatter.image})` }}></li>
        </Info>
      </HolderWrap>
    </MainWrap>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "delivery" } }
        frontmatter: { googleMapEmbed: {}, phone: {}, title: {} }
      }
    ) {
      nodes {
        html
        frontmatter {
          title
          title_en
          subTitle
          subTitle_en
          partners {
            url
            image
          }
          deliveryTitle
          deliveryTitle_en
          deliveryTimes {
            days
            days_en
            time
          }
          bodyMarkdown
          bodyMarkdown_en
          image
        }
      }
    }
  }
`

export default Lunch
